<template>
  <div class="relative">
    <th-popover
      data-testid="search-views-info"
      placement="bottom-start"
      class="absolute z-50"
      style="top: 1.9rem; left: 0rem"
    >
      <div class="p-4">
        <h4
          class="text-th-primary m-0 break-normal text-left"
          style="width: 250px"
        >
          {{ infoText }}
        </h4>
        <div class="pt-2">
          <div>
            {{ $t('pages.settings.pos.search.tabs.info.example.text') }}
          </div>
          <div class="border border-th-lunar-gray">
            <img :src="infoImage" width="274" height="205" />
          </div>
        </div>
      </div>
    </th-popover>

    <th-tabs-form-wrapper
      v-model="activeTab"
      :tabs="tabs"
      :content-overflow-visible="false"
      :has-border="false"
      class="th-tabs"
    >
      <template #tile-view>
        <search-form search-config-target="tile_view" data-testid="tile_view" />
      </template>
      <template #list-view>
        <search-form
          search-config-target="search_view"
          data-testid="list_view"
        />
      </template>
    </th-tabs-form-wrapper>
  </div>
</template>

<script>
import SearchForm from './components/search-form'
import tileView from '@/assets/images/search-pos-settings-tile-view.jpg'
import listView from '@/assets/images/search-pos-settings-list-view.png'

export default {
  components: { SearchForm },
  data() {
    return {
      activeTab: 'tile-view',
      tabs: [
        {
          label: this.$t('pages.settings.pos.search.tabs.tile-view.title'),
          name: 'tile-view'
        },
        {
          label: this.$t('pages.settings.pos.search.tabs.list-view.title'),
          name: 'list-view'
        }
      ]
    }
  },
  computed: {
    infoImage() {
      return this.activeTab === 'tile-view' ? tileView : listView
    },
    infoText() {
      return this.activeTab === 'tile-view'
        ? this.$t('pages.settings.pos.search.tabs.info.tile-view.text')
        : this.$t('pages.settings.pos.search.tabs.info.list-view.text')
    }
  }
}
</script>

<style scoped>
/* Override element ui */
.th-tabs :deep(.el-tabs__header) {
  margin-bottom: 2rem;
  padding-left: 2rem;
}
</style>
