<template>
  <section>
    <!-- Scanning barcodes -->
    <th-wrapper
      :title="$t('pages.settings.pos.search.scanning.barcodes.title')"
      :subtitle="$t('pages.settings.pos.search.scanning.barcodes.description')"
    >
      <!-- Sound toggle -->
      <div class="mb-4">
        <el-switch v-model="noResultSound" :active-text="noResultSoundText" />
      </div>

      <!-- Display when -->
      <el-radio-group
        id="search_no_result_actions"
        v-model="noResultAction"
        class="flex flex-col"
      >
        <el-radio label="toast">
          {{ $t('pages.settings.pos.search.no_result.actions.toast') }}
        </el-radio>
        <el-radio label="modal">
          {{ $t('pages.settings.pos.search.no_result.actions.modal') }}
        </el-radio>
        <el-radio label="none">
          {{ $t('pages.settings.pos.search.no_result.actions.none') }}
        </el-radio>
      </el-radio-group>
    </th-wrapper>

    <!-- Search time out -->
    <th-wrapper :title="$t('pages.settings.pos.search.timeout.title')">
      <el-radio-group
        id="search_timeout"
        v-model="searchTimeOut"
        class="flex flex-col"
      >
        <el-radio :label="3">
          {{ $t('pages.settings.pos.search.timeout.seconds.3') }}
        </el-radio>
        <el-radio :label="5">
          {{ $t('pages.settings.pos.search.timeout.seconds.5') }}
        </el-radio>
      </el-radio-group>
    </th-wrapper>

    <!-- Advanced search -->
    <th-wrapper
      :title="$t('pages.settings.pos.search.trigger_action.title')"
      :subtitle="$t('pages.settings.pos.search.trigger_action.description')"
    >
      <el-radio-group
        id="search_trigger_action"
        v-model="triggerAction"
        class="flex flex-col"
      >
        <el-radio label="on_first">
          {{ $t('pages.settings.pos.search.trigger_action.options.on_first') }}
        </el-radio>
        <el-radio label="on_unique">
          {{ $t('pages.settings.pos.search.trigger_action.options.on_unique') }}
        </el-radio>
        <el-radio label="on_none">
          {{ $t('pages.settings.pos.search.trigger_action.options.on_none') }}
        </el-radio>
      </el-radio-group>
    </th-wrapper>

    <!-- Scanning search -->
    <th-wrapper
      :title="$t('pages.settings.pos.search.scanning.search.title')"
      :subtitle="$t('pages.settings.pos.search.scanning.search.description')"
    >
      <div
        v-for="value in searchEntitiesLocal"
        :key="value.type"
        :label="value.type"
        class="mb-4"
      >
        <div class="mb-2">
          <el-checkbox v-model="value.enabled" @change="searchEntitiesChanged">
            {{ value.label }}
          </el-checkbox>
        </div>

        <el-select
          v-if="value.enabled"
          v-model="value.behavior"
          @change="searchEntitiesChanged"
        >
          <el-option
            v-for="item in searchBehaviors"
            :key="item.key"
            :label="item.text"
            :value="item.key"
          />
        </el-select>
      </div>
    </th-wrapper>
  </section>
</template>

<script>
import get from 'just-safe-get'

export default {
  props: {
    searchConfigTarget: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      searchEntitiesLocal: [
        {
          type: 'transactions',
          label: this.$t(
            'pages.settings.pos.search.entities.types.transactions'
          )
        },
        {
          type: 'products',
          label: this.$t('pages.settings.pos.search.entities.types.products')
        },
        {
          type: 'customers',
          label: this.$t('pages.settings.pos.search.entities.types.customers')
        },
        {
          type: 'vouchers',
          label: this.$t('pages.settings.pos.search.entities.types.vouchers')
        },
        {
          type: 'loyalty_cards',
          label: this.$t(
            'pages.settings.pos.search.entities.types.loyalty_cards'
          )
        },
        {
          type: 'hooks',
          label: this.$t('pages.settings.pos.search.entities.types.hooks')
        }
      ].map((obj) => ({
        ...obj,
        enabled: false,
        behavior: 'online_first'
      })),

      searchBehaviors: [
        {
          key: 'offline_first',
          text: this.$t(
            'pages.settings.pos.search.behavior.options.offline_first'
          )
        },
        {
          key: 'online_first',
          text: this.$t(
            'pages.settings.pos.search.behavior.options.online_first'
          )
        },
        {
          key: 'offline_only',
          text: this.$t(
            'pages.settings.pos.search.behavior.options.offline_only'
          )
        },
        {
          key: 'online_only',
          text: this.$t(
            'pages.settings.pos.search.behavior.options.online_only'
          )
        },
        {
          key: 'race',
          text: this.$t('pages.settings.pos.search.behavior.options.race')
        }
      ]
    }
  },
  computed: {
    noResultSoundText() {
      return this.noResultSound
        ? this.$t('pages.settings.pos.search.no_result.sound.enabled.text')
        : this.$t('pages.settings.pos.search.no_result.sound.disabled.text')
    },
    noResultSound: {
      get() {
        return (
          get(
            this.$store.getters['Config/getClientAccountConfiguration'],
            `searches.${this.searchConfigTarget}.no_result_sound`
          ) || false
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: `searches.${this.searchConfigTarget}.no_result_sound`,
          value: newValue
        })
      }
    },
    noResultAction: {
      get() {
        return (
          get(
            this.$store.getters['Config/getClientAccountConfiguration'],
            `searches.${this.searchConfigTarget}.no_result_action`
          ) || null
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: `searches.${this.searchConfigTarget}.no_result_action`,
          value: newValue
        })
      }
    },
    searchEntities: {
      get() {
        return (
          get(
            this.$store.getters['Config/getClientAccountConfiguration'],
            `searches.${this.searchConfigTarget}.search_entities`
          ) || []
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: `searches.${this.searchConfigTarget}.search_entities`,
          value: newValue
        })
      }
    },
    searchTimeOut: {
      get() {
        return (
          get(
            this.$store.getters['Config/getClientAccountConfiguration'],
            `searches.${this.searchConfigTarget}.timeout`
          ) || 0
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: `searches.${this.searchConfigTarget}.timeout`,
          value: newValue
        })
      }
    },
    triggerAction: {
      get() {
        return (
          get(
            this.$store.getters['Config/getClientAccountConfiguration'],
            `searches.${this.searchConfigTarget}.trigger_action`
          ) || null
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: `searches.${this.searchConfigTarget}.trigger_action`,
          value: newValue
        })
      }
    }
  },
  watch: {
    searchEntities() {
      this.updateLocalEntities()
    }
  },
  mounted() {
    this.updateLocalEntities()
  },
  methods: {
    updateLocalEntities() {
      const all = this.searchEntities
      this.searchEntitiesLocal.forEach((e) => {
        const f = all.find((t) => t.type === e.type)
        if (f) {
          e.enabled = true
          e.behavior = f.behavior
        } else {
          e.enabled = false
          e.behavior = 'online_first'
        }
      })
    },
    searchEntitiesChanged() {
      let filtered = this.searchEntitiesLocal.filter((t) => t.enabled)
      filtered = filtered.map((t) => {
        return { type: t.type, behavior: t.behavior }
      })
      this.searchEntities = filtered
    }
  }
}
</script>

<style scoped>
.box {
  width: 20rem;
}
</style>
